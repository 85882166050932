import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { format } from 'date-fns'

export default function BlogRecent() {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        limit: 3
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        nodes {
          frontmatter {
            date
            slug
            title
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 400)
              }
            }
            category
            language
          }
          excerpt
          html
          timeToRead
        }
      }
    }
  `)
  const { allMarkdownRemark } = data // data.markdownRemark holds your post data
  const { nodes } = allMarkdownRemark
  const posts = mapPosts(nodes)

  function mapPosts(posts: Array<any>) {
    return posts.map(post => {
      const featuredImage = getImage(post.frontmatter.featuredImage)
      return {
        title: post.frontmatter.title,
        href: post.frontmatter.slug,
        category: post.frontmatter.category,
        description: post.excerpt,
        datetime: post.frontmatter.date,
        featuredImage: featuredImage,
        language: post.frontmatter.language,
        timeToRead: post.timeToRead,
      }
    })
  }

  return (
    <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            Blog
          </h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            You can find my recent posts related to the engineering world here.
          </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {posts.map(post => (
            <div
              key={post.title}
              className="flex flex-col rounded-lg shadow-lg overflow-hidden"
            >
              <div className="flex-shrink-0 relative">
                <GatsbyImage
                  image={post.featuredImage}
                  alt=""
                  className="h-48 w-full object-cover inset-0 bg-cover bg-center z-0"
                />
                <div className="absolute inset-0 z-10 flex justify-end text-4xl text-white font-semibold mr-2">
                  {post.language}
                </div>
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-teal-600">
                    {post.category}
                  </p>
                  <Link to={post.href} className="block mt-2">
                    <p className="font-serif text-xl font-semibold text-gray-900">
                      {post.title}
                    </p>
                    <p className="mt-3 text-base text-gray-500">
                      {post.description}
                    </p>
                  </Link>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <Link to="/blog">
                      <span className="sr-only">Emiliano Viscarra</span>
                      <StaticImage
                        src="../images/avatar-emi.jpg"
                        placeholder="blurred"
                        alt="Author Emiliano"
                        width={40}
                        height={40}
                        className="h-10 w-10 rounded-full"
                      />
                    </Link>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                      <Link to="/blog" className="hover:underline">
                        Emiliano Viscarra
                      </Link>
                    </p>
                    <div className="flex space-x-1 text-sm text-gray-500">
                      <time dateTime={post.datetime}>
                        {format(new Date(post.datetime), 'PP')}
                      </time>
                      <span aria-hidden="true">&middot;</span>
                      <span>{post.timeToRead} mins read</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
