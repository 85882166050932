import * as React from 'react'
import Layout from '../components/layout'
import BlogRecent from '../components/blogRecent'
import Hero from '../components/hero'
import SEO from '../components/seo'

export default function IndexPage() {
  return (
    <Layout>
      <SEO title="Helping software engineers and startups grow" />
      <Hero />
      <BlogRecent />
    </Layout>
  )
}
