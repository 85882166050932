import * as React from 'react'
import { Link } from 'gatsby'
import resumePDF from '../files/Emiliano-Viscarra-Resume.pdf'
import { StaticImage } from 'gatsby-plugin-image'

export default function Hero() {
  return (
    <div>
      {/* Hero card */}
      <div className="relative">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
            <div className="absolute inset-0">
              <StaticImage
                src="../images/hero.jpg"
                alt="Coaching"
                className="h-full w-full object-cover"
              />
              <div className="absolute inset-0 bg-teal-900 mix-blend-multiply" />
            </div>
            <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8 font-light">
              <h1 className="font-serif text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                <span className="block text-white">Hi,</span>
                <span className="block text-teal-200">I'm Emiliano</span>
              </h1>
              <p className="mt-6 max-w-lg mx-auto text-center text-base sm:text-xl text-teal-200 sm:max-w-3xl">
                I specialize in helping companies{' '}
                <span className="font-extrabold">
                  transform prototypes into a product market fit
                </span>
                . I create an architecture proposal, assist setting the tech
                direction and define an engineering hiring plan.
              </p>
              <ul className="mt-6 px-6 max-w-lg mx-auto text-center text-base sm:text-xl text-teal-100 sm:max-w-3xl list-disc">
                <li>
                  What’s the best stack to build an idea fast without falling into
                  a tech debt trap?
                </li>
                <li>
                  Should I start with an in-house team or external contractors?
                </li>
                <li>
                  How do I follow up with the developers to keep alignment on a
                  daily basis?
                </li>
                <li>
                  Once live, how do I collect feedback and data to validate the
                  product?
                </li>
                <li>These are just a few examples</li>
              </ul>
              <p className="mt-6 max-w-lg mx-auto text-center font-extrabold text-base sm:text-xl text-teal-200 sm:max-w-3xl">
                I work with you to address these questions.
              </p>
              <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                  <a
                    href={resumePDF}
                    target="_blank"
                    className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-teal-700 bg-white hover:bg-teal-50 sm:px-8"
                  >
                    See Resume
                  </a>
                  <Link
                    to="/contact"
                    className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-teal-500 bg-opacity-60 hover:bg-opacity-70 sm:px-8"
                  >
                    Contact Me
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
